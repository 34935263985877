.swiper-container.swiper-container-initialized.swiper-container-horizontal {
  margin: 0px auto;
  max-height: 300px;
  // width: 90%;
}

img.responsive-img {
  // margin:0;
  width: 111.11px;
  height: 200px;
}

