
@import '../variables';


#myMobileFooterNav{ 

  margin: 0;
  width: 100%;
  height: 56px;
  border-radius: 20px 20px 0px 0px;
  background-color: $themeColor;
  position: fixed;
  bottom: 0;
  z-index: 1000;


  box-shadow: 0px 6px 12px rgba($color: #000000, $alpha: 0.2);


  display: none;
  // display: flex;       // it will turn flex under 800px width
  justify-content: space-between;
  align-items: center;
  

  @include mQ(800px){
    display: flex;
  }

  div{
    margin: 0px 10px;
    display: block;
    
    .myFooterIcons a {
      margin: 0px;

      font-family: Dosis, Aladin, sans-serif;
      font-size: 140%;
      color: $bodyBG;
   
    }

    

  }


}



