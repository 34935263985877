@import '../variables';




#myMobileLanding {
  margin: 0px;
  
  height: calc(100vh - 70px);  
}




#myMobileLandingTitleHolder {
  height: 200px;
  position: relative;
  top: 120px;



  
  #myMobileLandingIcon {
    margin: 0 auto;

    width: 270px;
    height: 270px;
    border-radius: 50%;

    // background: url("/logos/SilvenLEAF.jpg") center/cover;
    filter: opacity(0.1);

    position: absolute;
    top: -90px;
    right: 40px;
    z-index: -1;


    box-shadow: 0 0.125rem 0.25rem rgba(131, 83, 83, 0.7),
                0 -0.125rem 0.25rem rgba(131, 83, 83, 0.7);

    border: 17px solid $bodyBG;
  }






  h5{
    margin: 0px 0px 0px 20px;
    text-align-last: center;
    position: relative;    
    left: -110px;
  }      
  h1{
    margin: 0;

    font-size: 250%;
    font-family: 'Ranchers', Dosis, sans-serif;
    color: $themeColor;
    text-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
    
    text-align: center;
  }
  h3{
    margin: 0;
    font-size: 170%;
    text-align: center;
  }
  h6{
    margin: 0;
    text-align: center;
    color: $themeColor;
  }

}

a#myMobileLandingButton {
  margin: 0;
  font-size: 120%;
  color: $themeColor;
  font-family: Dosis, sans-serif;

  text-align: center;

  padding: 5px 10px;
  font-weight: bolder;
}

#myMobileLandingButtonHolder{
  height: 50px;
  border: 1px solid $themeColor;
  margin: 0 auto;

  margin-bottom: 30px;

  
  text-align: center;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  border-radius: 30px;

  box-shadow: 3px 3px 3px rgba(0,0,0,0.05);
}

#myLandingCarousalHolder {
  margin: 20px auto;
  width: 100%;
  margin-bottom: 0;
  position: absolute;
  bottom: 70px;

  @include mHeight(570px){
    display: none;
  }
}

