
/* --------------------------------------
.             VARIABLES
-------------------------------------- */



$redColor: #f00;
$blueColor: #2196f3;
$sweetPink: #ff0aa7;

$grayColor: #748c94;

$awesomeShadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);





$themeColor: #d00;
// $themeColor: #1073ff;
// $themeColor: #2733a1;
// $themeColor: $blueColor;
// $themeColor: #505;
$bodyBG: #f7f8fa;

// $demoLoginBG: complement($themeColor);
$demoLoginBG: #f1f2f4;




$landingIcon: url("../srcImages/landingIcon.png") center/cover;




$footerHeight: 120px;
$wrapperBottomMargin: -120px;














/* -------------------------
To use it, do this....
  li {
    @include mQ(300px. 1200px){
      font-size: 30px;
    }

------------------------- */


@mixin mQ($arg...) {
  @if length($arg) == 1 {
    @media screen and (max-width: nth($arg, 1)){
      @content;
    }
  }

  @if length($arg) == 2 {
    @media screen and (max-width: nth($arg, 1)) and (min-width: nth($arg, 2)) {
      @content;
    }
  }
}





@mixin mHeight($arg...) {
  @if length($arg) == 1 {
    @media screen and (max-height: nth($arg, 1)){
      @content;
    }
  }

  @if length($arg) == 2 {
    @media screen and (max-height: nth($arg, 1)) and (min-height: nth($arg, 2)) {
      @content;
    }
  }
}