@import '../variables';

#myLanding {
  margin: 0;
  height: calc(100vh - 56px);

  display: flex;
  justify-content: center;
  align-items: center;


  #myLandingIcon {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    box-shadow: 0 0.125rem 0.25rem rgba(131, 83, 83, 0.3),
                0 -0.125rem 0.25rem rgba(131, 83, 83, 0.3);
  
    // background: url("/logos/SilvenLEAF.png") center/cover;
    position: relative;
    border: 17px solid $bodyBG;
    cursor: pointer;
    

    &::before {
      // content: "";
      content: "";
      color: "#fff";
      text-align: center;

      position: absolute;
      transform: scale(1.07) translate3d(45px, -45px, 0px);
      background-color: rgba($color: $themeColor, $alpha: 1);

      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 19%;
    }
  }
  
  
  #myLandingTitleHolder {
    width: 550px;
    cursor: pointer;

  
    h5{
      margin: 0;
    }      
    h1{
      margin: 0;
      font-family: 'Ranchers', Dosis, sans-serif;
      color: $themeColor;
      text-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
    }
    h3{
      margin: 0;
      font-size: 200%;
      text-align: center;
      color: $themeColor;
    }
    h6{
      margin: 0;
      text-align: center;
    }



    
  }
}







#myLandingButtonHolder {
  margin-top: 50px;
  text-align: center;
  // border: 2px solid #f00;

  
  #myLandingButton {
    padding: 15px 30px;
    border-radius: 40px;
    
    color: #fff;
    font-size: 1.6em;
    border: 7px solid white;
    cursor: pointer;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.3);
       
  
    background-color: $themeColor;
  }


}


















