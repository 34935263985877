@import '../variables';


#mySkills {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  
}


.mySkillSectionHolder {
  margin: 100px 20px 50px 0px;
  border-radius: 10px;
  padding: 1.5rem 3rem;
  width: 300px;
  height: 420px;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
  position: relative;

  @include mQ(800px){
    margin: 50px auto;
    display: block;
    width: 70vw;
  }  

  .mySkillSectionIcon {
    background: $themeColor;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding-top: 1.25rem;
    position: absolute;
    top: -40px;
    left: 50%;
    margin-left: -40px;

    text-align: center;


    
    img {
      width: 40px;
      height: 40px;
      filter: invert(100%)
    }
  }

  .mySkillSectionTitle {
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 500;
    line-height: 1.2;
    font-family: Dosis;
  }

  ul li {
    margin: 0px 0px 10px 0px;
    font-family: 'Roboto', sans-serif;
    color: #58677c;
    // display: list-item;
    // text-align: -webkit-match-parent;



    img {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      position: relative;
      top: 5px;
    }
  }

}