@import '../variables';






/* -------------------------------
  .      Overriding for LEFT
  ------------------------------- */
.myImageLeftProjectHolder {
  // flex-flow: row;



  .myProjectImageHolder {
    text-align: right;


    .my3dProjectImage {
      position: relative;
      left: 25px;
      transform: translateZ(50px) rotateZ(17deg);
    }
  }



  .myProjectButtons {
    button {
      margin: 0px 20px 20px 0px;
    }
  }




  ul.myUsedTechnology li {
    margin-right: 15px;
  }





  .myProjectDescriptionHolder {
    margin-left: 20px;
    text-align: left;
  }

}







/* -------------------------------
. Overriding for RIGHT
------------------------------- */
.myImageRightProjectHolder {
  flex-flow: row-reverse;



  .myProjectImageHolder {
    text-align: left;

    .my3dProjectImage {
      position: relative;
      left: 25px;
      transform: translateZ(50px) rotateZ(17deg);
    }
  }





  .myProjectButtons {
    button {
      margin: 0px 0px 20px 20px;
    }
  }




  ul.myUsedTechnology li {
    margin-left: 15px;
  }





  .myProjectDescriptionHolder {
    margin-right: 20px;
    text-align: right;
  }

}












/* -------------------------------
  .           Main Styles
  ------------------------------- */




.myProjectHolder {
  margin: 0px 20px 120px 20px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;




  @include mQ(800px) {
    margin-left: 0;
    margin-right: 0;
    flex-flow: column !important;

    .myProjectImageHolder {
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 0px;
    }

    .myProjectDescriptionHolder {
      text-align: center !important;
    }
  }



  /* -------------------------------
  .           Images
  ------------------------------- */
  .myProjectImageHolder {
    perspective: 800px;
    width: 300px;



    img {
      margin: 0;
      height: 200px;
    }

  }







  /* -------------------------------
  .           Buttons
  ------------------------------- */
  .myProjectButtons {
    button {
      padding: 10px 20px;
      border-radius: 30px;

      color: #fff;
      font-size: 18px;
      border: none;

      cursor: pointer;


      background-color: $themeColor;



      @include mQ(390px) {
        margin-left: 0px !important;
        margin-right: 10px !important;
      }
    }


    button.mySeeLiveButton {
      background-color: $bodyBG;
      border: 1px solid $themeColor ;
      color: $themeColor;

      @include mQ(450px) {
        margin-bottom: 10px !important;
      }
    }



  }







  /* -------------------------------
  .           Technology
  ------------------------------- */
  ul.myUsedTechnology {
    text-align: justify;
    margin: 0;
    font-family: Dosis;
    font-weight: 800;


    li {
      display: inline-block;
    }
  }









  /* -------------------------------
  .           Description
  ------------------------------- */
  .myProjectDescriptionHolder {
    width: auto;
  }

  .myProjectDescription {
    margin: 0;
    margin-top: 10px;

    font-size: 125%;
    text-align: justify;
  }

}