@import url('https://fonts.googleapis.com/css2?family=Aladin&display=swap&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap&family=Dosis:wght@200;300;400;500;600;700;800&display=swap&family=Ranchers&display=swap');



/* --------------------------------------
.      font-family: 'Aladin', cursive;
.      font-family: 'Roboto', sans-serif;
.      font-family: Dosis;
.      font-family: 'Ranchers', cursive;
-------------------------------------- */


@import './variables';



/* ------------------------------------
.     pageTitle

.     (Navbar + Footer) Color
.     Navbar Font
.     Hamburger
.     Sticky Footer
.     Back Top Button
.     Form Color
------------------------------------ */


html {
  scroll-behavior: smooth;
}

body{  
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  font-family: Roboto, sans-serif;
  font-size: 14px;  

  background-color: $bodyBG;
}






/* ---------------------------
.           SCROLL
--------------------------- */
body::-webkit-scrollbar {
  // width: 0.25rem;
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;  
}

body::-webkit-scrollbar-thumb {
  background: $themeColor;    
  border-radius: 30px;
}











.myThemeColorText {
  color: $themeColor;
}

.myThemeColorBG {
  background-color: $themeColor;
}

.pageTitle {  
  margin: 25px 0px;

  font-size: 270%;
  font-weight: 800;
  font-family: Dosis, sans-serif;
}









#myFooter{
  background-color: $themeColor;  
}


.nav.nav-wrapper {
  background-color: #fff;
  box-shadow: none;  

  font-family: Dosis, sans-serif;
  font-weight: 500;

  .myProfileNavIcon{
    background-color: $bodyBG;
    
    
    color: $themeColor;
    font-family: Dosis;
    font-weight: 700;
    font-size: 170%;
    

    
    
  }
  
  .brand-logo{
    color: $themeColor;
    font-size: 270%;
    font-family: Aladin, Dosis, sans-serif;

    @include mQ(400px){
      font-size: 220%;
    }
  }
  
  ul.hide-on-med-and-down li a {
    color: $themeColor;
    font-size: 100%;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }

  ul.sidenav li a {
    font-size: 150%;
  }
}

nav ul.PCNav li  {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  a {
    height: 55px;
  }
  &::after {
    display: block;
    content: "";

    height: 3px;
    width: 0%;
    position: absolute;
    bottom: 0;
    background-color: $themeColor;
    border-radius: 30px;

    transition: all 300ms ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
}

nav ul.sidenav li:hover  a {  
  opacity: 0.5;
  // background-color: rgba($color: $themeColor, $alpha: 0.03);  
}

ul.sidenav {
  background-color: $bodyBG;  
  display: flex;
  flex-direction: column;
  height: 100%;

  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(100,0,0,0.05);
    position: absolute;
    z-index: -1;
    clip-path: circle(50% at 100% 0%);
  }
  &::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(100,0,0,0.05);
    position: absolute;
    z-index: -1;
    clip-path: circle(50% at 0% 100%);
  }
  
  li {
    // background-color: $bodyBG;
    &:nth-child(1) {
      // margin-top: 50px;
      margin-top: 200px;
    }

    &:last-child {
      margin-top: auto;
      margin-left: 0;
      text-align: center;
    }

    span.copyrightLi, .far.fa-copyright {
      font-size: 70%;
    }
  }
}









/* ------------------------------------
.              HAMBURGER
------------------------------------ */
.sidenav-trigger{
  position: relative;
  // top: 10px;
  cursor: pointer;
  color: $themeColor;
  i{
    margin: 0;
    // font-size: 200%;
  }
}
.sidenav-trigger > div {
  width: 30px;    
  height: 3px;
  margin: 7px;
  background-color: #fff;
  border-radius: 30px;
}























/* ----------------------------------
.           STICKY FOOTER
---------------------------------- */
#myWrapper{
  min-height: 100vh;
  margin-bottom: $wrapperBottomMargin;

  @include mQ(800px){
    min-height: 0;
    margin-bottom: 120px;
   

    overflow: auto;
  }
}

#myWrapper::after, #myFooter{
  content: "";
  display: block;

  height: $footerHeight;
  overflow: hidden;


  @include mQ(800px){
    display: none;
  }
}















/* ------------------------------------
.            BACK TO TOP BUTTON
------------------------------------ */
#myBackToTopButton {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: $themeColor;

  display: none;
  // display: flex;  it will become flex on scroll
  justify-content: center;
  align-items: center;

  border: 7px solid $bodyBG;
  color: #fff;
  font-size: 200%;

  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;


  @include mQ(800px){
    width: 60px;
    height: 60px;

    font-size: 190%;

    bottom: 70px;
    right: 20px;
  }
}













/* ------------------------------------
.    FORM INPUT INTO PRIMARY COLOR
------------------------------------ */
.input-field input + label {
  color: #000;
  pointer-events: none !important;

  span {
    margin: 0;
    font-size: 80%;
  }
}



.input-field input[type=text]:focus + label, .input-field input[type=number]:focus + label, .input-field input[type=email]:focus + label, .input-field input[type=password]:focus + label, .input-field .materialize-textarea:focus + label {
  color: $themeColor !important;
}

// .input-field input[type=text]:focus, .input-field input[type=number]:focus, .input-field input[type=email]:focus, .input-field input[type=password]:focus, .input-field .materialize-textarea:focus {
//   border-bottom: 1px solid $themeColor !important;
//   box-shadow: 0 1px 0 0 $themeColor !important;
// }


//  input[type=text]:focus,  input[type=number]:focus,  input[type=email]:focus,  input[type=password]:focus,  .materialize-textarea:focus {
//   border-bottom: 1px solid $themeColor !important;
//   box-shadow: 0 1px 0 0 $themeColor !important;
// }

input[type=text]:focus,  input[type=number]:focus,  input[type=email]:focus,  input[type=password]:focus,  .materialize-textarea, .materialize-textarea:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}





//---------------------ICON
.prefix.active{
  color: $themeColor !important;
}







//---------------------TABS
.tabs .tab a, .tabs .tab a:focus, .tabs .tab a:focus {
  background: $bodyBG !important;
  color: $themeColor !important;
}
.tabs .tab a.active, .tabs .tab a:focus, .tabs .tab a:focus.active {
  background: $themeColor !important;
  color: #fff !important;
}
.tabs .indicator {
   background-color: #000 !important;
}






//---------------------RADIO
[type="radio"]:checked + span:after, 
[type="radio"].with-gap:checked + span:before, 
[type="radio"].with-gap:checked + span:after {
 
  border: 2px solid $themeColor !important;
 
}
 
 
 
[type="radio"]:checked + span:after, 
[type="radio"].with-gap:checked + span:after {
 
  background-color: $themeColor !important;
 
}







// --------------------DATE
.datepicker-date-display {
  background-color: $themeColor !important;
 }
 
 .datepicker-cancel, .datepicker-clear, .datepicker-today, .datepicker-done {
  color:$themeColor !important;
 }
 
 .datepicker-table td.is-today {
  color: $themeColor !important;
 }
 
 .datepicker-table td.is-selected {
  background-color: $themeColor !important;
  color: #fff;
 }

 .dropdown-content.select-dropdown li span {
   color: $themeColor !important;
 }








 
/* --------------------------------------
.             DEFAULT BUTTONS
-------------------------------------- */
.btn.myBtn {
  background-color: $themeColor !important;
  &:visited {
    background-color: $themeColor !important;
  }
  &:active {
    background-color: $themeColor !important;
  }
  &:focus {
    background-color: $themeColor !important;
  }
  &:hover {
    background-color: darken($themeColor, 10) !important;
  }
}






.btn.myRedBtn {
  background-color: $redColor !important;
  &:visited {
    background-color: $redColor !important;
  }
  &:active {
    background-color: $redColor !important;
  }
  &:focus {
    background-color: $redColor !important;
  }
  &:hover {
    background-color: darken($redColor, 10) !important;
  }
}






.btn.myBlueBtn {
  background-color: $blueColor !important;
  &:visited {
    background-color: $blueColor !important;
  }
  &:active {
    background-color: $blueColor !important;
  }
  &:focus {
    background-color: $blueColor !important;
  }
  &:hover {
    background-color: darken($blueColor, 10) !important;
  }
}