@import '../variables';




#myAbout {
  display: flex;  
  justify-content: center;
  margin-bottom: 70px;
  user-select: auto;
  
  .myAboutInfoHolder .myAboutQA div {
    display: inline-block;
    @include mQ(800px){    
      display: block;
    }
  }
  
  .myAboutInfoHolder .myAboutQA {
    border-left: 1px solid rgba($color: $themeColor, $alpha: 0.1);
    border-bottom: 1px solid rgba($color: $themeColor, $alpha: 0.1);
    @include mQ(800px){    
      .myAboutAnswer {
        margin-bottom: 10px;
      }
      border-left: none;
      border-right: 1px solid rgba($color: $themeColor, $alpha: 0.1);
      border-bottom: 1px solid rgba($color: $themeColor, $alpha: 0.1);
    }
  }

  .myAboutParagraph {
    text-align: justify;
    user-select: text;

  }



  .myAboutTitle {
    width: 100px;
    text-align: right;

    font-weight: 800;
    font-family: Dosis;
    font-size: 150%;
    color: $themeColor;
    
    
    text-align: left;
    margin-left: 20px;
    @include mQ(800px){    
      margin-left: 0px;
      text-align: left;
    }
  }

  .myAboutAnswer {
    margin: 0px 0px 10px 10px;

    @include mQ(800px){    
      margin: 0;
    }
  }
}
